.invite-merchant-modal {
  .gooten-modal-content {
    width: 100%;
    max-width: 535px;
  }

  .default-role-message {
    color: #595959;
  }

  .gooten-modal-body {
    padding-bottom: 0px !important;

    .search-container {
      margin-bottom: 16px !important;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 24px;
  }

  .dropdown-option {
    display: flex;
    align-items: center;
    gap: 8px;

    .option-icon {
      width: 16px;
      height: 16px;
      color: #666;
    }
  }

  .dropdown-divider {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 12px;
    user-select: none;

    span {
      color: #666;
      font-size: 12px;
      white-space: nowrap;
    }

    .divider-line {
      height: 1px;
      background-color: #e0e0e0;
      flex: 1;
    }
  }

  .modal-description {
    color: #666;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 24px;
  }

  .invite-options {
    margin-top: 16px;

    .collapsible-section {
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        cursor: pointer;
        background-color: #f5f5f5;
        border-radius: 4px;

        &:hover {
          background-color: #eeeeee;
        }

        .header-content {
          display: flex;
          align-items: center;
          gap: 8px;

          svg {
            color: #666;
          }

          span {
            font-size: 14px;
            color: #333;
          }
        }
      }

      .section-content {
        padding: 16px;
        border-top: 1px solid #e0e0e0;

        .invite-link-container {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .gooten-input {
            width: 100%;

            &.invite-link-input.error {
              input {
                color: #982424;  
              }
            }
          }

          .loading-animation {
            margin-top: 8px;
            margin-left: 8px;
            width: 55px;
            text-align: center;
            
            .spinner-border {
              width: 24px;
              height: 24px;
            }
          }
        }

        .invite-email-container {
          display: flex;
          gap: 8px;
          align-items: flex-start;
          width: 100%;

          .email-pill-input {
            width: 100%;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 8px;
            min-height: 40px;
            cursor: text;
            background: white;
            max-height: 200px;
            overflow: hidden;

            &:focus-within {
              border-color: #0066cc;
              box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
            }

            .pills-container {
              display: flex;
              flex-wrap: wrap;
              gap: 6px;
              align-items: flex-start;
              width: 100%;
              max-height: 184px; 
              overflow-y: auto;
              padding-right: 4px; 

              &::-webkit-scrollbar {
                width: 6px;
              }

              &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 3px;
              }

              &::-webkit-scrollbar-thumb {
                background: #ccc;
                border-radius: 3px;
                cursor: pointer;

                &:hover {
                  background: #999;
                }
              }
            }

            .email-pill {
              display: flex;
              align-items: center;
              background: #f0f0f0;
              border-radius: 16px;
              padding: 0px 6px 0px 10px;
              font-size: 13px;
              gap: 6px;

              .remove-pill {
                cursor: pointer;
                color: #666;
                
                &:hover {
                  color: #ff4444;
                }
              }
            }

            input {
              border: none;
              outline: none;
              flex: 1;
              min-width: 120px;
              font-size: 14px;
              background: transparent;

              &::placeholder {
                color: #999;
              }

              &:disabled {
                background: transparent;
                cursor: not-allowed;
              }
            }
          }

          .loading-animation {
            margin-top: 8px;
            margin-left: 8px;
            width: 115px;
            text-align: center;
            
            .spinner-border {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 24px;
  }
}
